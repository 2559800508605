<ng-container *ngIf="!editMode; else change">
    <button mat-stroked-button color="primary" (click)="changeEditMode()">
        <i class="fa-solid fa-bolt"></i> Change
    </button>
</ng-container>
<ng-template #change>
    <div>
        <button mat-stroked-button color="warn" class="mx-2" (click)="updateOrder()" [disabled]="formInvalid">
            <i class="fa-solid fa-floppy-disk"></i> Save
        </button>
        <button mat-stroked-button color="primary" (click)="changeEditMode()">
            Cancel <i class="fa-solid fa-xmark"></i>
        </button>
    </div>
</ng-template>
