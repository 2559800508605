import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ChatMessageService} from "../../../../shared/services/chat-message.service";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SnackbarService} from "../../../../shared/services/snackbar.service";

@Component({
    selector: 'app-chat-message-modal',
    templateUrl: './chat-message-modal.component.html',
    styleUrl: './chat-message-modal.component.scss'
})
export class ChatMessageModalComponent implements OnInit {

    public serviceOrderID = 0;
    public chatMessageForm!: FormGroup;

    // validators min/max rules
    public chatMessageMinLength = 3;
    public chatMessageMaxLength = 400;

    constructor(private chatMessageService: ChatMessageService,
                protected formBuilder: FormBuilder,
                private snackbarService: SnackbarService,
                private dialogRef: MatDialogRef<ChatMessageModalComponent>,
                @Inject(MAT_DIALOG_DATA)
                    data: {
                    serviceOrderID: number
                }) {
        this.serviceOrderID = data.serviceOrderID;
    }

    ngOnInit() {
        this.buildChatMessageForm();
    }

    sendChatMessage() {
        this.serviceOrderIDForm.setValue(this.serviceOrderID);
        this.chatMessageService.createMessage(this.chatMessageForm.getRawValue()).subscribe({
            next: (res) => this.snackbarService.snackbarCustomComplete(res.message),
            error: () => this.snackbarService.snackbarError(),
            complete: () => this.dialogRef.close()
        });
    }

    public get message(): AbstractControl {
        return this.chatMessageForm.controls['message'];
    }

    private get serviceOrderIDForm(): AbstractControl {
        return this.chatMessageForm.controls['service_order_id'];
    }

    private buildChatMessageForm(): void {
        this.chatMessageForm = this.formBuilder.group({
            service_order_id: [''],
            message: ['', {
                validators: [
                    Validators.required, Validators.minLength(this.chatMessageMinLength), Validators.maxLength(this.chatMessageMaxLength)]
            }],
        });
    }
}
