import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

// modules
import {MaterialModule} from "../shared/material/material.module";
import {SharedModule} from "../shared/shared.module";

// guard
import {authGuard} from "../shared/guard/auth.guard";

// components
import {SidenavMenuComponent} from './components/sidenav-menu/sidenav-menu.component';
import {SidenavToolbarComponent} from './components/sidenav-toolbar/sidenav-toolbar.component';
import {ServiceOrderFormComponent} from './service-orders/service-order-form/service-order-form.component';
import {ServiceOrderSaveComponent} from './service-orders/service-order-save/service-order-save.component';
import {ServiceOrdersListComponent} from './service-orders/service-orders-list/service-orders-list.component';
import {
    ServiceOrderStatusColorComponent
} from './service-orders/components/service-order-status-color/service-order-status-color.component';
import {
    ServiceOrderMessageComponent
} from './service-orders/components/service-order-message/service-order-message.component';
import {
    ServiceOrderSaveButtonComponent
} from './service-orders/components/service-order-save-button/service-order-save-button.component';
import {
    ServiceOrderStatusEditComponent
} from './service-orders/components/service-order-status-edit/service-order-status-edit.component';
import {
    ServiceOrderStatusEditButtonComponent
} from './service-orders/components/service-order-status-edit-button/service-order-status-edit-button.component';
import {ChatMessageModalComponent} from './service-orders/components/chat-message-modal/chat-message-modal.component';

// secure pages
import {SecureComponent} from './secure.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {ServiceOrdersComponent} from './service-orders/service-orders.component';
import {ServiceOrderComponent} from './service-orders/service-order/service-order.component';
import {UsersComponent} from './users/users.component';
import {ProfileComponent} from './profile/profile.component';
import {UsersListComponent} from './users/users-list/users-list.component';


const routes: Routes = [

    // secure pages
    {
        path: '',
        component: SecureComponent,
        canActivate: [authGuard],
        children: [
            {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
            {path: 'dashboard', component: DashboardComponent, title: "Dashboard"},
            {path: 'service-order-add', component: ServiceOrderSaveComponent, title: "Service Order Add"},
            {path: 'service-orders', component: ServiceOrdersComponent, title: "Service Orders"},
            {path: 'service-order/:id', component: ServiceOrderComponent, title: "Service Order"},
            {path: 'users', component: UsersComponent, title: "Users"},
            {path: 'profile', component: ProfileComponent, title: "Profile"}
        ]
    }

];

@NgModule({
    declarations: [
        SecureComponent,
        DashboardComponent,
        ProfileComponent,
        SidenavMenuComponent,
        SidenavToolbarComponent,
        ServiceOrdersComponent,
        ServiceOrderFormComponent,
        ServiceOrderSaveComponent,
        ServiceOrdersListComponent,
        ServiceOrderStatusColorComponent,
        ServiceOrderComponent,
        ServiceOrderMessageComponent,
        ServiceOrderSaveButtonComponent,
        ServiceOrderStatusEditComponent,
        ServiceOrderStatusEditButtonComponent,
        ChatMessageModalComponent,
        UsersComponent,
        UsersListComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        NgOptimizedImage,
        MaterialModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule
    ]
})
export class SecureModule {
}
