import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {MediaMatcher} from "@angular/cdk/layout";
import {AuthService} from "../shared/services/auth.service";

@Component({
    selector: 'app-secure',
    templateUrl: './secure.component.html',
    styleUrl: './secure.component.scss'
})
export class SecureComponent implements OnInit, OnDestroy {

    public mobileQuery: MediaQueryList;

    private readonly _mobileQueryListener: () => void;

    constructor(changeDetectorRef: ChangeDetectorRef,
                media: MediaMatcher,
                protected authService: AuthService) {
        this.mobileQuery = media.matchMedia('(max-width: 600px)');
        this._mobileQueryListener = (): void => changeDetectorRef.detectChanges();
        this.mobileQuery.addEventListener('change', this._mobileQueryListener);
    }

    public ngOnInit(): void {
        this.authService.getUser();
    }

    public ngOnDestroy(): void {
        this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
    }
}
