<div class="pt-2">
    <ng-container *ngIf="!editMode else editStatus">
        <h2>status: <span class="text-success">
            <app-service-order-status-color
                [status]="serviceOrder.status"></app-service-order-status-color>
         </span></h2>
    </ng-container>
    <ng-template #editStatus>
        <form [formGroup]="statusForm">
            <mat-form-field class="mx-xl-2" appearance="outline">
                <mat-label>status:</mat-label>
                <mat-select formControlName="status">
                    <mat-option [value]="'created'" disabled>created</mat-option>
                    <mat-option [value]="'in_progress'">in_progress</mat-option>
                    <mat-option [value]="'not_finished'">not_finished</mat-option>
                    <mat-option [value]="'completed'">completed</mat-option>
                    <mat-option [value]="'canceled'">canceled</mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </ng-template>
    <app-service-order-status-edit-button (edit)="changeEditMode()"
                                          (update)="updateOrderStatus()">
    </app-service-order-status-edit-button>
</div>
