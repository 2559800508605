import {Component, Input} from '@angular/core';
import {ServiceOrderMessage} from "../../../../shared/models/service-orders/service-order-message";
import {User} from "../../../../shared/models/user/user";

@Component({
    selector: 'app-service-order-message',
    templateUrl: './service-order-message.component.html',
    styleUrl: './service-order-message.component.scss'
})
export class ServiceOrderMessageComponent {
    @Input() public message!: ServiceOrderMessage;
    @Input() public user!: User;
}
