import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatSort, Sort} from "@angular/material/sort";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatSelectChange} from "@angular/material/select";
import {Router} from "@angular/router";

// services
import {AuthService} from "../../../shared/services/auth.service";
import {ServiceOrdersService} from "../../../shared/services/service-orders.service";

// models
import {ServiceOrder} from "../../../shared/models/service-orders/service-order";
import {ServiceOrders} from "../../../shared/models/service-orders/service-orders";

// date picker
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {FormControl} from "@angular/forms";
import _moment from 'moment';


const moment = _moment;

export const DATE_FORMAT = {
    parse: {
        dateInput: ['YYYY-MM-DD']
    },
    display: {
        dateInput: 'DD/MM/Y',
        monthYearLabel: 'DD MM Y',
        dateA11yLabel: 'DD MM Y',
        monthYearA11yLabel: 'DD MM Y',
    },
};

@Component({
    selector: 'app-service-orders-list',
    templateUrl: './service-orders-list.component.html',
    styleUrl: './service-orders-list.component.scss',
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        {provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT},
    ],
})
export class ServiceOrdersListComponent implements OnInit, AfterViewInit {


    @ViewChild(MatSort) public sort!: MatSort;
    @ViewChild(MatPaginator) public paginator!: MatPaginator;

    public isLoading = false;
    public displayedColumns: string[] = ['number', 'date', 'type', 'status', 'show'];
    public orders$ = new MatTableDataSource<ServiceOrder>();
    public pageSizeOptions: number[] = [6, 12, 24, 48];
    public totalRows = 0;
    public pageSize = 6;
    public currentPage = 0;
    public ordersSort = 'id';
    public ordersDirection = 'desc';

    // filters
    public date = new FormControl<_moment.Moment | null>(moment());
    public dateFilter: string | undefined = '';
    public typeFilter = '';
    public statusFilter = '';

    constructor(protected authService: AuthService,
                private ordersService: ServiceOrdersService,
                private router: Router) {
    }

    ngAfterViewInit(): void {
        this.orders$.paginator = this.paginator;
        this.paginator.firstPage();
    }

    ngOnInit(): void {
        this.loadOrders();
    }

    public pageChanged(event: PageEvent): void {
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex + 1;
        this.loadOrders();
    }

    public showOrder(id: number): void {
        this.router.navigateByUrl(`secure/service-order/${id}`).then();
    }

    public sortData(sort: Sort): void {
        switch (sort.active) {
            case 'number':
                this.ordersSort = 'id';
                this.ordersDirection = sort.direction;
                this.loadOrders();
                return
            case 'date':
                this.ordersSort = sort.active;
                this.ordersDirection = sort.direction;
                this.loadOrders();
                return
            case 'type':
                this.ordersSort = sort.active;
                this.ordersDirection = sort.direction;
                this.loadOrders();
                return
            case 'status':
                this.ordersSort = sort.active;
                this.ordersDirection = sort.direction;
                this.loadOrders();
                return
            default:
                return
        }
    }

    public searchByDate(): void {
        this.dateFilter = this.date.getRawValue()?.format('YYYY-MM-DD')
        this.loadOrders();
        this.paginator.firstPage();
    }

    public setTypeFilter(option: MatSelectChange): void {
        this.typeFilter = option.value;
        this.loadOrders();
        this.paginator.firstPage();
    }

    public setStatusFilter(option: MatSelectChange): void {
        this.statusFilter = option.value;
        this.loadOrders();
        this.paginator.firstPage();
    }

    public cleanFilter(): void {
        this.dateFilter = '';
        this.typeFilter = '';
        this.statusFilter = '';
        this.date.setValue(moment());
        this.loadOrders();
        this.paginator.firstPage();
    }

    private loadOrders(): void {
        this.isLoading = true;
        const userType = this.authService.user.type;
        this.ordersService.index(
            userType,
            this.currentPage,
            this.pageSize,
            this.ordersSort,
            this.ordersDirection,
            this.dateFilter,
            this.typeFilter,
            this.statusFilter
        )
            .subscribe(
                (orders: ServiceOrders): void => {
                    this.orders$ = new MatTableDataSource(orders.data);
                    this.orders$.sort = this.sort;
                    this.paginator.length = orders.meta.total;
                    this.paginator.pageIndex = 0;
                    this.paginator.pageIndex = orders.meta.current_page - 1;
                    this.isLoading = false;
                }
            );
    }
}
