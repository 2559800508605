import {Component, EventEmitter, Input, Output} from '@angular/core';

// model
import {ServiceOrder} from "../../../../shared/models/service-orders/service-order";
import {AbstractControl, FormBuilder, FormGroup} from "@angular/forms";

@Component({
    selector: 'app-service-order-status-edit',
    templateUrl: './service-order-status-edit.component.html',
    styleUrl: './service-order-status-edit.component.scss'
})
export class ServiceOrderStatusEditComponent {

    @Input() public serviceOrder!: ServiceOrder;
    @Output() public status = new EventEmitter();

    public editMode = false;
    public statusForm!: FormGroup;

    constructor(private formBuilder: FormBuilder) {
    }

    public get orderStatus(): AbstractControl {
        return this.statusForm.controls['status'];
    }

    public changeEditMode(): void {
        this.editMode = !this.editMode;
        this.buildStatusForm();
        this.orderStatus.setValue(this.serviceOrder.status);
    }

    public updateOrderStatus(): void {
        this.status.emit(this.statusForm.getRawValue());
        this.editMode = !this.editMode;
    }

    private buildStatusForm(): void {
        this.statusForm = this.formBuilder.group({
            status: ['']
        });
    }

}
