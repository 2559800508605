import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'app-service-order-save-button',
    templateUrl: './service-order-save-button.component.html',
    styleUrl: './service-order-save-button.component.scss'
})
export class ServiceOrderSaveButtonComponent {

    @Input() public invalid = true;

    @Output() public saveProduct = new EventEmitter<void>();
    @Output() public closeForm = new EventEmitter<void>();

    public saveProductEmit(): void {
        this.saveProduct.emit();
    }

    public closeFormEmit(): void {
        this.closeForm.emit();
    }
}
