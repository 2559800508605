<div class="py-2 px-2 py-md-5 px-md-5">
    <div class="row">
        <div *ngIf="isLoading; else content">
            <div class="d-flex justify-content-center mt-5">
                <mat-spinner></mat-spinner>
            </div>
        </div>
        <ng-template #content>
            <div class="col-md-6">
                <mat-card class="bg-white mat-elevation-z3">
                    <a class="d-flex flex-row-reverse link-secondary mx-2 mt-2" routerLink="/secure/service-orders">
                        <mat-icon aria-hidden="false" matTooltip="Close" matTooltipPosition="below">close</mat-icon>
                    </a>
                    <div class="col-9 header">
                        <mat-card-header>
                            <mat-card-title><i class="fa-solid fa-list-check text-primary-color"></i>
                                Service Order Summary
                            </mat-card-title>
                            <mat-card-subtitle>Information about the service request.</mat-card-subtitle>
                        </mat-card-header>
                    </div>
                    <mat-card-content>
                        <div class="py-4">
                            <h4>number: <b class="text-primary-color">{{ serviceOrder.id }} </b></h4>
                            <h4>type: <b class="text-primary-color">{{ serviceOrder.type }}</b></h4>
                            <h4>date: <b class="text-primary-color">{{ serviceOrder.date | date:'dd/MM/y' }}</b>
                                time: <b class="text-primary-color"> {{ serviceOrder.date | date:'hh:mm' }}</b>
                            </h4>
                            <h2 *ngIf="authService.user.type === 'user'">status: <span> <app-service-order-status-color
                                [status]="serviceOrder.status"></app-service-order-status-color></span></h2>
                            <app-service-order-status-edit *ngIf="authService.user.type !== 'user'"
                                                           [serviceOrder]="serviceOrder"
                                                           (status)="updateOrder($event)">
                            </app-service-order-status-edit>
                            <div class="mt-3">
                                <ng-container *ngIf="authService.user.type !== 'user'">
                                    <h5>reporter: <b class="text-primary-color">{{ serviceOrderReporter.company_name }}</b>
                                    </h5>
                                </ng-container>
                                <div class="pt-2">
                                    <h6>description:</h6>
                                    <p>{{ serviceOrder.description }}</p>
                                </div>
                            </div>
                            <div class="mt-4">
                                <h4><i class="fa-solid fa-location-dot text-primary-color"></i> address:</h4>
                                <ul class="no-bullets">
                                    <li>{{ serviceOrder.addresses[0].name }}</li>
                                    <li>{{ serviceOrder.addresses[0].street_name }} {{ serviceOrder.addresses[0].street_number }}
                                        {{ serviceOrder.addresses[0].apartment_number ? '/ ' + serviceOrder.addresses[0].apartment_number : '' }}
                                    </li>
                                    <li>{{ serviceOrder.addresses[0].zip_code }} {{ serviceOrder.addresses[0].city_name }}</li>
                                    <li><a class="text-decoration-none text-primary-color"
                                           href="tel:{{ serviceOrder.addresses[0].phone_number}}"><i
                                        class="fas fa-phone"></i>
                                        <a class="text-decoration-none fw-bold text-primary-color"
                                           href="tel:{{ serviceOrder.addresses[0].phone_number}}"> {{ serviceOrder.addresses[0].phone_number }}</a></a>
                                    </li>
                                    <li><i
                                        class="fa-brands fa-intercom text-primary-color"></i> {{ serviceOrder.addresses[0].intercom_code }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-md-6 mt-3 mt-xl-0" *ngIf="serviceOrder.messages.length > 0">
                <mat-card class="bg-white mat-elevation-z3">
                    <mat-card-header>
                        <mat-card-title><i class="fa-solid fa-comment text-primary-color"></i>
                            Messages
                        </mat-card-title>
                        <mat-card-subtitle>Chat messages regarding service order.</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="py-2" *ngFor="let message of serviceOrder.messages">
                            <app-service-order-message [user]="authService.user"
                                                       [message]="message">
                            </app-service-order-message>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </ng-template>
    </div>
    <div class="col-md-6 text-center py-5">
        <button class="mx-2" mat-stroked-button routerLink="/secure/service-orders"><i
            class="fa-solid fa-rotate-left"></i> close
        </button>
        <button mat-stroked-button (click)="addMessage()"><i class="fa-solid fa-comment"></i> Add Message</button>
    </div>
</div>
