import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-service-order-status-color',
    templateUrl: './service-order-status-color.component.html',
    styleUrl: './service-order-status-color.component.scss'
})
export class ServiceOrderStatusColorComponent {
    @Input() public status = '';
}
