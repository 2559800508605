<div class="py-2 px-2 py-md-5 px-md-5">
    <div class="row">
        <div class="col-md-6">
            <mat-card class="bg-white mat-elevation-z3">
                <div class="row">
                    <div class="col-md-6">
                        <mat-card-header>
                            <mat-card-title>
                                <mat-icon class="mx-1" color="primary">playlist_add</mat-icon>
                                Service Order Add
                            </mat-card-title>
                            <mat-card-subtitle>Add a new service order.</mat-card-subtitle>
                        </mat-card-header>
                    </div>
                </div>
                <mat-card-content>
                    <div class="pt-4">
                        <app-service-order-form #data></app-service-order-form>
                        <app-service-order-save-button
                            [invalid]="data.serviceOrderForm.invalid"
                            (closeForm)="closeForm()"
                            (saveProduct)="addServiceOrder()">
                        </app-service-order-save-button>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
