import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatSidenav} from "@angular/material/sidenav";
import {Title} from "@angular/platform-browser";

// models
import {User} from "../../../shared/models/user/user";

@Component({
    selector: 'app-sidenav-toolbar',
    templateUrl: './sidenav-toolbar.component.html',
    styleUrl: './sidenav-toolbar.component.scss'
})
export class SidenavToolbarComponent {

    @Input() public user!: User | null;
    @Input() public sidenav!: MatSidenav;

    @Output() public logout: EventEmitter<void>;

    constructor(private titleService: Title) {
        this.logout = new EventEmitter();
    }

    public getTitle(): string {
        return this.titleService.getTitle();
    }

    public logoutEmit(): void {
        this.logout.emit();
    }
}
