<div class="d-flex justify-content-end pt-4 pb-3">
    <button class="full-width mx-2" mat-raised-button (click)="closeFormEmit()"
            matTooltip="Close form" matTooltipPosition="below">
        <i class="fa-solid fa-xmark"></i> Close
    </button>
    <button class="full-width mx-2" mat-raised-button color="primary" (click)="saveProductEmit()"
            matTooltip="Save product" matTooltipPosition="below" [disabled]="invalid">
        Add <i class="fa-solid fa-plus"></i>
    </button>
</div>
