import {Component} from '@angular/core';

@Component({
    selector: 'app-service-orders',
    templateUrl: './service-orders.component.html',
    styleUrl: './service-orders.component.scss'
})
export class ServiceOrdersComponent {

}
