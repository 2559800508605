import {inject} from "@angular/core";
import {CanActivateFn, Router} from '@angular/router';
import {AuthService} from "../services/auth.service";
import {MatSnackBar} from "@angular/material/snack-bar";

export const authGuard: CanActivateFn = (route, state) => {
    const authService: AuthService = inject(AuthService);
    const snackbar: MatSnackBar = inject(MatSnackBar);
    const router: Router = inject(Router);

    if (!authService.isLogged) {
        snackbar.open('Access denied, please login.', '', {panelClass: 'snackbar-error'});
        router.navigate(['/auth/login'], {queryParams: {returnURL: state.url}}).then();
    }

    return authService.isLogged;
};
