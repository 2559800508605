import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

// services
import {AuthService} from "../../../shared/services/auth.service";
import {ServiceOrdersService} from "../../../shared/services/service-orders.service";

// models
import {ServiceOrder} from "../../../shared/models/service-orders/service-order";
import {User} from "../../../shared/models/user/user";
import {MatDialog} from "@angular/material/dialog";
import {ChatMessageModalComponent} from "../components/chat-message-modal/chat-message-modal.component";

@Component({
    selector: 'app-service-order',
    templateUrl: './service-order.component.html',
    styleUrl: './service-order.component.scss'
})
export class ServiceOrderComponent implements OnInit {

    public isLoading = false;
    public serviceOrderID = 0;
    public serviceOrder!: ServiceOrder;
    public serviceOrderReporter!: User;

    constructor(protected authService: AuthService,
                private ordersService: ServiceOrdersService,
                private route: ActivatedRoute,
                private dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.serviceOrderID = Number(this.route.snapshot.paramMap.get('id'));
        this.getServiceOrder(this.serviceOrderID);
    }

    public updateOrder(updateOrderData: Partial<ServiceOrder>): void {
        this.ordersService.update(this.serviceOrderID, updateOrderData).subscribe((order: ServiceOrder): void => {
            this.serviceOrder = order;
        })
    }

    public addMessage(): void {
        this.dialog.open(ChatMessageModalComponent, {
            maxWidth: '100vw',
            maxHeight: '100vh',
            width: '650px',
            height: '30vh',
            data: {
                serviceOrderID: this.serviceOrderID
            }
        }).afterClosed().subscribe(
            (): void => {
                this.getServiceOrder(this.serviceOrderID);
            }
        );
    }

    private getServiceOrder(id: number): void {
        this.isLoading = true;
        const userType = this.authService.user.type;
        this.ordersService.getServiceOrder(userType, id).subscribe((serviceOrder: ServiceOrder): void => {
            this.serviceOrder = serviceOrder;
            if (this.authService.user.type !== 'user') {
                this.getUserData(serviceOrder.user_id);
            }
            this.isLoading = false;
        })
    }

    private getUserData(id: number): void {
        this.authService.getUserData(id).subscribe((user: User): void => {
            this.serviceOrderReporter = user;
        })
    }
}
