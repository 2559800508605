<mat-card class="bg-white mat-elevation-z3">
    <mat-card-header>
        <mat-card-title>
            <mat-icon class="mx-1" color="primary">checklist</mat-icon>
            Service Orders
        </mat-card-title>
        <mat-card-subtitle>
            Check service orders list.
        </mat-card-subtitle>
        <div class="mx-3" *ngIf="authService.user.type === 'user'">
            <button mat-fab color="warn" matTooltip="Add new service order" routerLink="/secure/service-order-add"
                    matTooltipPosition="right" aria-label="Add new service order">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </mat-card-header>
    <mat-card-content>
        <div class="pt-4 pb-3">
            <mat-accordion>
                <mat-expansion-panel class="bg-white" (opened)="cleanFilter()" (closed)="cleanFilter()">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <i class="fa-solid fa-filter text-primary-color mx-2"></i> Filter
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-form-field appearance="outline" color="primary">
                        <mat-label>Select date</mat-label>
                        <input matInput [matDatepicker]="picker" [formControl]="date">
                        <mat-hint>DD/MM/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker (closed)="searchByDate()"></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="py-2 mx-1 mx-xl-3" appearance="outline" color="primary">
                        <mat-label>Type</mat-label>
                        <mat-select (selectionChange)="setTypeFilter($event)"
                                    [(ngModel)]="typeFilter">
                            <mat-option [value]="''">all</mat-option>
                            <mat-option [value]="'gas'">gas</mat-option>
                            <mat-option [value]="'water'">water</mat-option>
                            <mat-option [value]="'sewers'">sewers</mat-option>
                            <mat-option [value]="'heating'">heating</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="py-2 mx-1 mx-xl-3" appearance="outline" color="primary">
                        <mat-label>Status</mat-label>
                        <mat-select (selectionChange)="setStatusFilter($event)"
                                    [(ngModel)]="statusFilter">
                            <mat-option [value]="''">all</mat-option>
                            <mat-option [value]="'created'">created</mat-option>
                            <mat-option [value]="'in_progress'">in_progress</mat-option>
                            <mat-option [value]="'not_finished'">not_finished</mat-option>
                            <mat-option [value]="'completed'">completed</mat-option>
                            <mat-option [value]="'canceled'">canceled</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button mat-stroked-button color="primary" (click)="cleanFilter()">
                        <mat-icon fontIcon="cleaning_services"></mat-icon>
                        clear
                    </button>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div class="table-container">
            <table mat-table [dataSource]="orders$" matSort (matSortChange)="sortData($event)" matSortActive="number"
                   matSortDisableClear matSortDirection="desc">

                <!-- number column -->
                <ng-container matColumnDef="number">
                    <th class="table-cell-width" mat-header-cell *matHeaderCellDef mat-sort-header="number">number</th>
                    <td mat-cell *matCellDef="let order">{{ order.id }}</td>
                </ng-container>

                <!-- date column -->
                <ng-container matColumnDef="date">
                    <th class="table-cell-width" mat-header-cell *matHeaderCellDef mat-sort-header="date">date</th>
                    <td mat-cell *matCellDef="let order">{{ order.date | date:'dd/MM/y' }}</td>
                </ng-container>

                <!-- type column -->
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="type">type</th>
                    <td mat-cell *matCellDef="let order">{{ order.type }}</td>
                </ng-container>

                <!-- status column -->
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="status">status</th>
                    <td mat-cell *matCellDef="let order">
                        <app-service-order-status-color [status]="order.status"></app-service-order-status-color>
                    </td>
                </ng-container>

                <!-- show column -->
                <ng-container matColumnDef="show">
                    <th class="table-cell-width" mat-header-cell *matHeaderCellDef>show</th>
                    <td mat-cell *matCellDef="let order">
                        <div class="d-flex">
                            <button mat-mini-fab color="indigo" matTooltip="Show"
                                    (click)="showOrder(order.id)"
                                    matTooltipPosition="left" aria-label="Show">
                                <mat-icon>remove_red_eye</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        <mat-paginator class="bg-white" [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                       [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)">
        </mat-paginator>
        <ng-container *ngIf="!orders$.data.length">
            <div class="text-center py-5">
                <p>No service orders found.</p>
            </div>
        </ng-container>
        <div class="mt-3" *ngIf="isLoading">
            <mat-progress-bar mode="indeterminate" *ngIf="isLoading" color="primary"></mat-progress-bar>
        </div>
    </mat-card-content>
</mat-card>
