import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

// models
import {ServiceOrderMessageData} from "../models/service-orders/service-order-message-data";
import {ApiMessage} from "../models/api-message";

@Injectable({
    providedIn: 'root'
})
export class ChatMessageService {

    constructor(protected http: HttpClient) {
    }

    // add/create service order chat message in to API
    createMessage(data: ServiceOrderMessageData): Observable<ApiMessage> {
        return this.http.post<ApiMessage>(`${environment.api}/message`, data);
    }

}
