import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatSidenav} from "@angular/material/sidenav";
import {User} from "../../../shared/models/user/user";

@Component({
    selector: 'app-sidenav-menu',
    templateUrl: './sidenav-menu.component.html',
    styleUrl: './sidenav-menu.component.scss'
})
export class SidenavMenuComponent {

    @Input() public sidenav!: MatSidenav;
    @Input() public mobileQuery!: MediaQueryList;
    @Input() public user!: User;

    @Output() public logout: EventEmitter<void>;

    constructor() {
        this.logout = new EventEmitter();
    }

    public logoutEmit(): void {
        this.logout.emit();
    }
}
