import {Component} from '@angular/core';
import {AuthService} from "../../shared/services/auth.service";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrl: './profile.component.scss'
})
export class ProfileComponent {

    constructor(protected authService: AuthService) {
    }

}
