import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

// models
import {ServiceOrders} from "../models/service-orders/service-orders";
import {ServiceOrder} from "../models/service-orders/service-order";
import {ServiceOrderData} from "../models/service-orders/service-order-data";
import {ApiMessage} from "../models/api-message";

@Injectable({
    providedIn: 'root'
})
export class ServiceOrdersService {

    constructor(protected http: HttpClient) {
    }

    // get all service orders for a logged admin/member/user form API
    public index(userType: string, page?: number, perPage?: number, sort?: string, direction?: string,
                 date?: string, type?: string, status?: string): Observable<ServiceOrders> {

        let url = this.getURLbyUserType(userType);

        if (perPage) url += `?page=${page}&perPage=${perPage}&sort=${sort}&direction=${direction}&date=${date}&type=${type}&status=${status}`;

        return this.http.get<ServiceOrders>(url);
    }

    // get one order for a logged admin/member/user form API
    public getServiceOrder(userType: string, id: number): Observable<ServiceOrder> {
        const url = this.getURLbyUserType(userType) + `/${id}`;
        return this.http.get<ServiceOrder>(url);
    }

    // add/create service order in to API
    createServiceOrder(data: ServiceOrderData): Observable<ApiMessage> {
        return this.http.post<ApiMessage>(`${environment.api}/user-service-order`, data);
    }

    // update one service order in API only for admin
    update(id: number, data: Partial<ServiceOrderData>): Observable<ServiceOrder> {
        return this.http.put<ServiceOrder>(`${environment.api}/admin-service-order/${id}`, data);
    }

    // get API URL by a logged admin/member/user
    private getURLbyUserType(userType: string): string {
        let url = '';
        switch (userType) {
            case 'admin':
                url = `${environment.api}/admin-service-order`;
                break;
            case 'member':
                url = `${environment.api}/member-service-order`;
                break;
            case 'user':
                url = `${environment.api}/user-service-order`;
                break;
        }
        return url;
    }
}
