import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'app-service-order-status-edit-button',
    templateUrl: './service-order-status-edit-button.component.html',
    styleUrl: './service-order-status-edit-button.component.scss'
})
export class ServiceOrderStatusEditButtonComponent {

    @Input() public formInvalid = false;

    @Output() public edit = new EventEmitter();
    @Output() public update = new EventEmitter();

    public editMode = false;

    public changeEditMode(): void {
        this.setEditMode();
        this.edit.emit();
    }

    public updateOrder(): void {
        this.update.emit();
        this.setEditMode();
    }

    private setEditMode(): void {
        this.editMode = !this.editMode;
    }
}
