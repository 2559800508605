<div *ngIf="authService.isLoading; else content">
    <div class="wrapper-spinner">
        <mat-spinner></mat-spinner>
    </div>
</div>
<ng-template #content>
    <mat-sidenav-container fullscreen>
        <mat-sidenav #sidenav [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches"
                     fixedTopGap="56" [opened]="!mobileQuery.matches" [disableClose]="!mobileQuery.matches">
            <app-sidenav-menu [sidenav]="sidenav" [user]="authService.user" [mobileQuery]="mobileQuery"
                              (logout)="authService.logout('/')"></app-sidenav-menu>
        </mat-sidenav>
        <mat-sidenav-content>
            <app-sidenav-toolbar [user]="authService.user" [sidenav]="sidenav"
                                 (logout)="authService.logout('/')"></app-sidenav-toolbar>
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</ng-template>
