<div class="py-2 px-2 py-md-5 px-md-5">
    <div class="row align-items-md-stretch g-3">
        <div class="col-md-6">
            <mat-card class="mat-elevation-z3 bg-white">
                <mat-card-header>
                    <mat-card-title>
                        <mat-icon class="mx-1" color="primary">person</mat-icon>
                        <span> Profile Info</span>
                    </mat-card-title>
                    <mat-card-subtitle>Check your profile information.</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <mat-list>
                        <mat-list-item>
                            <span matListItemTitle>{{ authService.user.name }}</span>
                            <span matListItemLine>name/nickname</span>
                        </mat-list-item>
                        <mat-list-item>
                            <span matListItemTitle>{{ authService.user.company_name }}</span>
                            <span
                                matListItemLine>{{ authService.user.type === 'member' ? 'full name' : 'company name' }}</span>
                        </mat-list-item>
                        <mat-list-item>
                            <span matListItemTitle>{{ authService.user.email }}</span>
                            <span matListItemLine>e-mail address</span>
                        </mat-list-item>
                        <mat-list-item>
                            <span matListItemTitle>{{ authService.user.type }}</span>
                            <span matListItemLine>account type</span>
                        </mat-list-item>
                        <mat-list-item>
                            <span matListItemTitle>{{ authService.user.regulations ? 'Yes' : 'No' }}</span>
                            <span matListItemLine>Confirmation that you have read the regulations.</span>
                        </mat-list-item>
                        <mat-list-item>
                            <span matListItemTitle>{{ authService.user.confirmed ? 'Yes' : 'No' }}</span>
                            <span matListItemLine>E-mail address confirmed.</span>
                        </mat-list-item>
                    </mat-list>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
